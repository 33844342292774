import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Box, Link } from '@material-ui/core';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Link as RouterLink } from "react-router-dom";
import React, { useCallback } from 'react';
import { useEffect } from 'react';
import PreloadImage from './ImagePreloader';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactHtmlParser from 'react-html-parser';
import { Mixpanel } from '../mixpanel';
import { useInView } from 'react-intersection-observer';
import { attemptProductGlimpseEvent } from '../redux/ProductsListRedux';

interface stylesProps {
  width: string,
  margin: string,
}

const useStyles=({width,margin}: stylesProps) => makeStyles((theme: Theme) =>
createStyles({
  svgImage: {
    width: width,
    margin: margin,
    minHeight: '70vw',
  },
  circularProgressContainer: {
    width: width,
    aspectRatio: '2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: "300px 0px 300px 0px",
  }
}),
);

interface PageProductItemProps {
  svg_url: string,
  linkTo?: string,
  width?: string,
  margin?: string,
}

export default function PageElementSvg({svg_url, linkTo, width, margin} : PageProductItemProps) {
  width = width ? width : '100%';
  margin = margin ? margin : '0px 0px 0px 0px';
  const classes = useStyles({width, margin})();
  const dispatch = useAppDispatch();

  const [imgSrc, setImgSrc] = React.useState<string>('');

  // Intersection Observer API
  const { ref, inView, entry } = useInView({
    threshold: [0.02, 0.05, 0.1, 0.2],
    triggerOnce: true,
    rootMargin: '400px',
  });

  // Intersection Observer API for viewing event
  const { ref: ref2, inView: inView2, entry: entry2 } = useInView({
    threshold: 0.7,
    triggerOnce: false,
    rootMargin: '10px',
  });

  useEffect(() => {
    if (inView) {
      const imgHoverNew = new Image();
      imgHoverNew.onload = () => {
        setImgSrc(imgHoverNew.src);
      }
      imgHoverNew.src = svg_url;
    }
  }, [svg_url, inView]);

  useEffect(() => {
    if (inView2) {
      if (linkTo) {
        if (linkTo.startsWith("/shop/product/")) {
          const product_id = linkTo.split("/")[3];
          dispatch(attemptProductGlimpseEvent(product_id));
        }
      }
    }
  }, [inView2]);

  // Use `useCallback` so we don't recreate the function on each render
  const setRefs = useCallback(
    (node) => {
      ref(node);
      ref2(node);
    },
    [ref, ref2],
  );


  return (
    <div ref={setRefs} onClick={() => {
      if (linkTo) {
        if (linkTo.startsWith("/shop/product/")) {
          const product_id = linkTo.split("/")[3];
          Mixpanel.track('navigateRoute', {
            'routePath': linkTo,
            'routeSource': 'pageProductListItem',
          });
          Mixpanel.track('productClickToProductDetailPage', {
            'productId': "productId-" + product_id,
            'productUrl': linkTo,
            'routeSource': 'pageProductListItem',
          });
          console.log("Tracking mixpanel productClickToProductDetailPage for product " + product_id);
        }
      }
    }}>
      {
        // imgSrc === '' ? 
        // <div className={classes.circularProgressContainer}>
        //   <CircularProgress/> 
        // </div> :
        linkTo === undefined ?
        <img src={svg_url} className={classes.svgImage} /> :
        <Link component={RouterLink} to={linkTo === undefined ? "" : linkTo}>
          <img src={svg_url} className={classes.svgImage} />
        </Link>
      }
    </div>
  )
}