import { Box, Button, Dialog, IconButton, Paper, Slide, Typography, useMediaQuery, Fade } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';
import { changePopupStage, closeOverlayAndReset, openOverlay } from '../redux/UIOverlayMktplcStartRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import { Close } from '@material-ui/icons';
import { sendWebsiteEventMessage } from '../telegrambotevents';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      zIndex: ((theme.zIndex.modal + 1) + " !important") as any,
      '& .MuiPaper-root': {
        borderRadius: "20px",
      },
      '& .MuiBackdrop-root': { 
        backgroundColor: "rgba(0, 0, 0, 0.7)"
      } 
    },
    dialogContentContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center', 
      textAlign: 'center',
    },
    dialogDivImage: {
      backgroundColor: '#181818',
      maxWidth: '80vw',
      overflow: "hidden",
      position: "relative",
      [theme.breakpoints.up("sm")]: {
        // height: "80vh",
      }
    },
    dialogDivImageBannerOngkir: {
      width: "100%",
      overflow: "hidden",
      position: "relative"
    },
    dialogDivImageCustom: {
      width: "100%",
      height: "61vw",
      overflow: "hidden",
      position: "relative"
    },
    mainImg: {
      margin: '0px 0px -1vw',
      maxWidth: '80vw',
      objectFit: "cover",
      [theme.breakpoints.up("sm")]: {
        height: "80vh",
      }
    },
    mainImgBannerOngkir: {
      width: "100%",
      borderRadius: "4vw",
      [theme.breakpoints.up("sm")]: {
        borderRadius: "20px",
      }
    },
    mainImgCustom: {
      width: "102%",
      height: "61vw",
      objectFit: "cover"
    },
    dialogDivImageCloseButton: {
      position: "absolute",
      top: "0",
      right: "0",
      margin: "4px",
      color: "#ffffff",
      zIndex: 2,
    },
    dialogDivPromoText: {
      position: "absolute",
      top: "6vw",
      left: "6.8vw",
      color: "#ffffff",
      backgroundColor: "#C01414",
      zIndex: 2,
      fontSize: "2.3vw",
      fontWeight: 600,
      letterSpacing: "0.1vw",
      padding: "0.8vw 1.2vw 0 1.2vw"
    },
    dialogDivPromoTextBannerOngkir: {
      position: "absolute",
      top: "5.6vw",
      left: "6.8vw",
      color: "#ffffff",
      backgroundColor: "#C01414",
      zIndex: 2,
      fontSize: "2.2vw",
      fontWeight: 600,
      letterSpacing: "0.1vw",
      padding: "0.7vw 1vw 0 1vw",
      [theme.breakpoints.up("sm")]: {
        fontSize: "11px",
        letterSpacing: "1.5px",
        top: "19px",
        left: "34px",
        padding: "6px 14px 2px 8px",
      }
    },
    dialogDivMonthText: {
      position: "absolute",
      top: "44vw",
      left: "6.8vw",
      color: "#ffffff",
      zIndex: 2,
      fontSize: "2.3vw",
      fontWeight: 500,
      letterSpacing: "0.1vw",
      textAlign: "left",
    },
    dialogDivMonthTextBannerOngkir: {
      position: "absolute",
      top: "38vw",
      left: "6.8vw",
      color: "#ffffff",
      zIndex: 2,
      fontSize: "2.1vw",
      fontWeight: 500,
      letterSpacing: "0.14vw",
      textAlign: "left",
      [theme.breakpoints.up("sm")]: {
        fontSize: "11px",
        letterSpacing: "1.5px",
        top: "185px",
        left: "34px",
      }
    },
    textBannerOngkir: {
      fontSize: "2.7vw",
      fontWeight: 400,
      padding: "1.2vw 0 3.8vw 0",
      textUnderlineOffset: "3px",
      textDecorationThickness: "0.8px",
      textDecorationLine: "underline",
      cursor: "pointer",
      WebkitTapHighlightColor: "transparent",
    },
    dialogDivTextSvg: {
      width: "100%"
    },
    dialogDivText: {
      width: "calc(100% - 10vw)",
      padding: "4.5vw 5vw 1vw 5vw",
    },
    ongkirTitleText: {
      margin: "1vw 0 2vw 0",
      fontSize: "3.8vw",
      textAlign: "left",
      fontWeight: 600,
      color: "#232323",
    },
    ongkirContentText: {
      margin: "1vw 0 0vw 0",
      fontSize: "2.5vw",
      letterSpacing: "0.08vw",
      textAlign: "left",
      fontWeight: 300,
      lineHeight: "4.5vw",
    },
    tanpaPerluUkurSubheaderText: {
      margin: "0.5vw 0 1.6vw 0",
      fontSize: "2.6vw",
      letterSpacing: "0.2vw",
      textAlign: "left",
      fontWeight: 300,
      color: "#232323",
    },
    tanpaPerluUkurTitleText: {
      margin: "1vw 0 2vw 0",
      fontSize: "5.2vw",
      textAlign: "left",
      fontWeight: 600,
      color: "#232323",
    },
    tanpaPerluUkurContentText: {
      margin: "1vw 0 0vw 0",
      fontSize: "2.8vw",
      textAlign: "left",
      fontWeight: 300,
      lineHeight: "5vw",
    },
    mainTextSvg: {
      width: "calc(100% - 9vw)",
      marginTop: "5vw",
      marginBottom: "2vw"
    },
    dialogDivButtons: {
      width: "100%",
      height: "18vw",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    dialogDivCarousel: {
      width: "100%",
      height: "18vw",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      position: "relative",
    },
    styledIndicators: {
      width: "100%",
      marginTop: "2px",
      textAlign: "center"
    },
    styledIndicatorsHidden: {
      width: "100%",
      marginTop: "2px",
      textAlign: "center",
      opacity: "0"
    },
    indicatorSvgIcon: {
      width: "0.52em",
      margin: "0.05em"
    },
    secondaryFinishButtonOverlay: {
      position: "absolute",
      right: "145px",
      bottom: "15px",
      fontWeight: theme.typography.fontWeightMedium as number,
      color: '#232323',
      fontSize: '3.7vw',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.5vw',
      },
    },
    finishButtonOverlay: {
      position: "absolute",
      right: "10px",
      bottom: "13px",
      fontWeight: theme.typography.fontWeightMedium as number,
      color: '#ffffff',
      backgroundColor: '#121212',
      borderRadius: '30px',
      padding: '9px 20px 8px 20px',
      fontSize: '3.7vw',
      '&:hover': {
        backgroundColor: '#eaeaea',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.5vw',
      },
    },
    firstButton: {
      fontWeight: theme.typography.fontWeightMedium as number,
      color: '#ffffff',
      backgroundColor: '#121212',
      borderRadius: '30px',
      padding: '9px 20px 8px 20px',
      fontSize: '3.7vw',
      '&:hover': {
        backgroundColor: '#eaeaea',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.5vw',
      },
      margin: "0 14px 0 14px"
    },
    secondButton: {
      fontWeight: theme.typography.fontWeightMedium as number,
      color: '#121212',
      fontSize: '3.7vw',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.5vw',
      },
    },
    closeBarMobile: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end', 
    },
    textTitle: {
      fontSize: '17px',
      marginBottom: '20px',
      marginLeft: '10px',
      marginRight: '10px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '24px',
        marginBottom: '20px',
        marginLeft: '10px',
        marginRight: '10px',
      },
    },
    textContent: {
      fontSize: '12px',
      marginBottom: '20px',
      marginLeft: '20px',
      marginRight: '20px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
        marginBottom: '20px',
        marginLeft: '10px',
        marginRight: '10px',
      },
    },
    textContent2: {
      fontSize: '13px',
      marginBottom: '40px',
      marginLeft: '20px',
      marginRight: '20px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '15px',
        marginBottom: '40px',
        marginLeft: '10px',
        marginRight: '10px',
      },
    },
    styledItem: {
      position: "absolute",
      height: "100%",
      width: '100%',
    },
    styledRoot: {
      position: "relative",
      overflow: "hidden",
    },
    styledItemWrapper: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    hiddenArrowIcon: {
      opacity: 0,
    }
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} timeout={170} />;
});

export default function OverlayView() {
  const overlayView = useAppSelector(state => state.overlayMktplcStart);
  const theme = useTheme();
  const isSmOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [displayButton, setDisplayButton] = useState(false);
  const [nextCloseTime, setNextCloseTime] = useState(new Date().toISOString());

  const [addInternalPopupOn, setAddInternalPopupOn] = useState(false);

  // POP UP LUAR
  if (overlayView.marketplaceLinkDict?.["chosenKey"] === "intro") {
    if (overlayView.popupStage === '1-INTRO') {
      return (
        <Dialog
        open={overlayView.status === UIOverlayViewStatus.On}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        onClose={() => {
          dispatch(changePopupStage('2-HOWTO'));
          setDisplayButton(false);
          setAddInternalPopupOn(false);
          setNextCloseTime(new Date(new Date().getTime() + 7 * 1000).toISOString());
          // setTimeout(() => {
          //   setDisplayButton(true);
          // }, 7000);
          sendWebsiteEventMessage("User NEXT ongkir popup MKTPLC intro");
        }}
        className={classes.dialogContainer}>
          <div className={classes.dialogContentContainer}>
            <div className={classes.dialogDivImage}>
              <Box style={{
                backgroundColor: '#181818',
                maxWidth: '80vw',
                margin: '0px 0px -1vw',
                maxHeight: "80vh",
                aspectRatio: '2/3',
                display: 'flex',
                flexDirection: 'column',
              }} 
              onClick={() => {
                dispatch(changePopupStage('2-HOWTO'));
                setDisplayButton(false);
                setAddInternalPopupOn(false);
                setNextCloseTime(new Date(new Date().getTime() + 7 * 1000).toISOString());
                // setTimeout(() => {
                //   setDisplayButton(true);
                // }, 7000);
                sendWebsiteEventMessage("User NEXT ongkir popup MKTPLC intro");
              }}>
                <video key={'https://studiosclo.uk/general/SquarePopupLuar_2.mp4'} loop autoPlay muted playsInline
                  style={{
                    width: '100%',
                  }}>
                  <source src={'https://studiosclo.uk/general/SquarePopupLuar_2.mp4'} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <img 
                src={"https://studiosclo.uk/general/TextPopupLuar_2.jpg"} 
                alt="measkit-img"
                style={{
                  width: '100%',
                }}
                onClick={() => {
                  dispatch(changePopupStage('2-HOWTO'));
                  setDisplayButton(false);
                  setAddInternalPopupOn(false);
                  setNextCloseTime(new Date(new Date().getTime() + 7 * 1000).toISOString());
                  // setTimeout(() => {
                  //   setDisplayButton(true);
                  // }, 7000);
                  sendWebsiteEventMessage("User NEXT ongkir popup MKTPLC intro");
                }}/>
              </Box>
              {/* <IconButton aria-label="closebutton" className={classes.dialogDivImageCloseButton} 
              onClick={() => {
                dispatch(changePopupStage('2-HOWTO'));
                sendWebsiteEventMessage("User NEXT ongkir popup MKTPLC intro");
              } }>
                <Close />
              </IconButton> */}
            </div>
          </div>
        </Dialog>
      );
    } else {
      return (
        <>
        <Dialog
        open={overlayView.status === UIOverlayViewStatus.On}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        onClose={() => {
          if (overlayView.marketplaceLinkDict?.["chosen"] !== "0") {
            setAddInternalPopupOn(true);
            return;
          }
          // if (!displayButton && nextCloseTime > (new Date().toISOString())) {
          //   return;
          // }
          dispatch(closeOverlayAndReset());
          sendWebsiteEventMessage("User closed ongkir popup MKTPLC intro");
        }}
        className={classes.dialogContainer}>
          <div className={classes.dialogContentContainer}>
            <div className={classes.dialogDivImage}>
              <Box style={{
                backgroundColor: '#181818',
                maxWidth: '80vw',
                margin: '0px 0px -5px',
                maxHeight: "80vh",
                aspectRatio: '2/3',
                display: 'flex',
                flexDirection: 'column',
              }} 
              onClick={() => {
                if (overlayView.marketplaceLinkDict?.["chosen"] !== "-1") {
                  setAddInternalPopupOn(true);
                  return;
                }
                // if (!displayButton && nextCloseTime > (new Date().toISOString())) {
                //   return;
                // }
                dispatch(closeOverlayAndReset());
                sendWebsiteEventMessage("User closed ongkir popup MKTPLC intro");
              }}>
                <img 
                src={"https://studiosclo.uk/general/PopUpLuarMktplcImg2.jpg"} 
                alt="measkit-img-2"
                style={{
                  width: '100%',
                }}/>
                {/* <Fade in={true}> */}
                  <Button style={{
                    backgroundColor: '#000000',
                    border: '1px solid #dedede',
                    borderRadius: '30px',
                    padding: '4px 2px 3px',
                    position: 'absolute',
                    bottom: '17px',
                    color: '#ffffff',
                    width: '80%',
                    left: '10%',
                    fontSize: '12px',
                  }}
                  onClick={() => {
                    if (overlayView.marketplaceLinkDict?.["chosen"] !== "-1") {
                      setAddInternalPopupOn(true);
                      return;
                    }
                    // if (!displayButton && nextCloseTime > (new Date().toISOString())) {
                    //   return;
                    // }
                    dispatch(closeOverlayAndReset());
                    sendWebsiteEventMessage("User closed ongkir popup MKTPLC intro");
                  }}>
                    LANJUT BELANJA
                  </Button>
                {/* </Fade> */}
              </Box>
            </div>
          </div>
        </Dialog>
        <Dialog
        open={addInternalPopupOn}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        onClose={() => {
          setAddInternalPopupOn(false);
          dispatch(closeOverlayAndReset());
          sendWebsiteEventMessage("User closed ongkir popup MKTPLC intro");
        }}
        className={classes.dialogContainer}>
          <div className={classes.dialogContentContainer}>
            <div className={classes.dialogDivImage}>
              <Box style={{
                backgroundColor: '#181818',
                maxWidth: '80vw',
                margin: '-2px -2px -5px',
                maxHeight: "80vh",
                aspectRatio: '2',
                display: 'flex',
                flexDirection: 'column',
              }} 
              onClick={() => {
                setAddInternalPopupOn(false);
                dispatch(closeOverlayAndReset());
                sendWebsiteEventMessage("User closed ongkir popup MKTPLC intro");
              }}>
                <img 
                src={"https://studiosclo.uk/general/PopUpLuarMktplcImgRepop1.jpg"} 
                alt="measkit-img-2"
                style={{
                  width: '100%',
                }}/>
              </Box>
            </div>
          </div>
        </Dialog>
        </>
      );
    }
  }

  // POP UP DALAM
  return (
    <Dialog
    open={overlayView.status === UIOverlayViewStatus.On}
    TransitionComponent={Transition}
    keepMounted
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
    onClose={() => {
      dispatch(closeOverlayAndReset());
      sendWebsiteEventMessage("User closed ongkir popup MKTPLC");
    }}
    className={classes.dialogContainer}>
      <div className={classes.dialogContentContainer}>
        <div className={classes.dialogDivImage}>
          <img 
          key={"ajakan-mp-" + overlayView.marketplaceLinkDict?.["chosenKey"]}
          src={"https://studiosclo.uk/general/pop_up_marketplace_" + overlayView.marketplaceLinkDict?.["chosenKey"] + "_5.jpg"} 
          alt="measkit-img"
          className={classes.mainImg} onClick={() => {
            sendWebsiteEventMessage("User OUT TO MARKETPLACE");

            if (overlayView.marketplaceLinkDict?.["chosen"]) {
              window.open(overlayView.marketplaceLinkDict["chosen"], '_blank');
            }
          }}/>
          <IconButton aria-label="closebutton" className={classes.dialogDivImageCloseButton} 
          onClick={() => {
            dispatch(closeOverlayAndReset());
            sendWebsiteEventMessage("User closed ongkir popup MKTPLC");
          } }>
            <Close />
          </IconButton>
        </div>
      </div>
    </Dialog>
  )
}