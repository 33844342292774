import { Box, Button, Grid, Typography, useMediaQuery, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { RegularSizeValue } from 'common-ts/dist/models/Cart';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import OverlayComponentBaseContainerHorizontal from './OverlayComponentBaseContainerHorizontal';
import OverlayComponentBaseContainerWidth from './OverlayComponentBaseContainerWidth';
import OverlayComponentButton, { FareastButton, FareastButtonGrey } from './OverlayComponentButton';
import { openOverlay as openOverlaySizeChart } from '../redux/UIOverlaySizeChartRedux';
import OverlayComponentBaseTemplate from './OverlayComponentBaseTemplate';
import OverlayComponentTitle from './OverlayComponentTitle';
import OverlayComponentCanvas from './OverlayComponentCanvasFitAnimation';
import { ClothingMeasurements, ClothingType, FitType, CalculateClothingMeasurementsFromBodyMeasurements, BodyMeasurementsForSize } from 'common-ts/dist/models/UserMeasurements';
import { updateSizeChartClothFitType } from '../redux/UIOverlaySizeChartRedux';
import { ArrowRight } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '300px',
      [theme.breakpoints.only('xs')]: {
        width: '240px',
      }
    },
    form: {
      height: 'auto',
      padding: '0px 24px 0px 24px',
      [theme.breakpoints.only('xs')]: {
        padding: '0px 0px 0px 0px',
      }
    },
    formRow: {
      paddingBottom: '30px',
    },
    subFormTitle: {
    },
    toggleSignUpSignInContainer: {
      paddingTop: '30px',
      paddingBottom: '30px',
      // paddingLeft: '10px',
      paddingRight: '10px',
    },
    buttonContainer: {
      width: '80%',
      paddingBottom: '12px',
      [theme.breakpoints.only('xs')]: {
        paddingBottom: '6px',
      },
      position: 'relative'
    },
    gridItemCanvasContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    canvasContainer: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.only('xs')]: {
        height: '75vw',
      }
    },
    ukuranRegulerBoxSizeChartLink: {
      marginBottom: "8px",
      fontSize: "11px",
      color: "#5e5e5e",
      textDecoration: "underline #5e5e5e solid 1px",
      cursor: "pointer",
      '-webkit-tap-highlight-color': 'transparent',
    },
    ukuranRegulerSizeTextBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: "1vw",
      paddingLeft: "0.4vw",
      paddingRight: "0.4vw",
      [theme.breakpoints.down('xs')]: {
        marginTop: "4vw",
      }
    },
  }),
);

function enumKeys<E>(e: E): (keyof E)[] {
  return Object.keys(e as any) as (keyof E)[];
}

export interface Props {
  productId: string | undefined
  clothingType: ClothingType
  fitType: FitType
  key2: string
  handleSubmit: (value: RegularSizeValue) => void
  isMinimized?: boolean
  useMobileView?: boolean
}

export default function RegularSelectSize({productId, clothingType, fitType, key2, handleSubmit, isMinimized, useMobileView}: Props) {
  const productSizeAvailabilityDict = useAppSelector(state => state.productsList.productSizeAvailabilityDict);
  const productSizeAvailObject = productId ? productSizeAvailabilityDict?.[productId] : undefined;
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm")) && !useMobileView;
  const [selected, setSelected] = React.useState<RegularSizeValue | undefined>(undefined);
  const [goalFit, setGoalFit] = React.useState<ClothingMeasurements>({
    lingkarLeherBaju: 40,
    lebarBahuBaju: 48,
    lebarDadaBaju: 52,
    lebarPerutBaju: 52,
    panjangBaju: 70,
    lebarLenganBaju: 20,
    panjangLenganPendek: 24,
    panjangLenganPanjang: 58,
  })

  useEffect(() => {
    setGoalFit(CalculateClothingMeasurementsFromBodyMeasurements(clothingType, fitType, 
      BodyMeasurementsForSize[RegularSizeValue.NOTSELECTED], RegularSizeValue.L));
  }, [clothingType]);

  let children: JSX.Element[] = [];
  let submitChildren: JSX.Element[] = [];

  children.push(
    <OverlayComponentBaseContainerHorizontal>
      <OverlayComponentBaseContainerWidth
        widthMdUp="100%"
        widthSmDown="100%">
        <Grid container>
          {
            !isMdOrUp ?
            <Grid item xs={12} className={classes.gridItemCanvasContainer}>
              <Box className={classes.canvasContainer} style={isMinimized ? {height: useMobileView ? '200px' : '50vw'} : {}}>
                <OverlayComponentCanvas
                clothingType={clothingType}
                fitType={fitType}
                putAtTop={false}
                scaleRatio={isMinimized ? 0.7 : 0.5}
                incrementTogether={true}
                goalFit={goalFit}/>
              </Box>
              <Typography className={classes.ukuranRegulerBoxSizeChartLink}
              onClick={() => {
                dispatch(updateSizeChartClothFitType({
                  clothingType: clothingType,
                  fitType: fitType
                }));
                dispatch(openOverlaySizeChart());
              }}>
                + LIHAT SIZE CHART
              </Typography>
            </Grid> : 
            null
          }
          <Grid item xs={12} sm={useMobileView ? 12 : 6}
            style={{
              marginLeft: useMobileView ? '20px' : undefined
            }}
          >
            <Grid container>
              {
                enumKeys(RegularSizeValue).map((item, i) => {
                  if (item === "NOTSELECTED" || item === "XXXL" || item === "XXXXL" || item === "XXXXXL") {
                    return;
                  }
                  return (
                    <Grid item xs={4} sm={4}>
                      <Box className={classes.buttonContainer}>
                        {
                          selected === RegularSizeValue[item] ?
                          <FareastButton fullWidth
                            onClick={() => {
                              if (selected !== undefined) {
                                handleSubmit(selected);
                              }
                            }}
                          buttonMarginTopBottom="10px"
                          buttonMarginLeftRight="0px"
                          >
                            {
                              item
                            }
                          </FareastButton> :
                          <FareastButtonGrey fullWidth onClick={() => {
                            setSelected(RegularSizeValue[item]);
                            setGoalFit(CalculateClothingMeasurementsFromBodyMeasurements(clothingType, fitType, 
                              BodyMeasurementsForSize[RegularSizeValue[item]], RegularSizeValue.L));
                          }}
                          buttonMarginTopBottom="10px"
                          buttonMarginLeftRight="0px"
                          disabled={ 
                            productSizeAvailObject?.availabilityArray?.[i - 1] === undefined ? 
                            false : 
                            !productSizeAvailObject.availabilityArray[i - 1] 
                          }
                          >
                            {
                              item
                            } 
                          </FareastButtonGrey>
                        }
                        {
                          selected === RegularSizeValue[item] ?
                          <IconButton
                          style={{
                            position: 'absolute',
                            right: '-13px',
                            top: '2px',
                            color: '#ffffff'
                          }}
                          onClick={() => {
                            if (selected !== undefined) {
                              handleSubmit(selected);
                            }
                          }}>
                            <ArrowRight fontSize={'large'}/>
                          </IconButton>
                          : null
                        }
                      </Box>
                    </Grid>
                  );
                })
              }
            </Grid>
          </Grid>
          {
            isMdOrUp ?
            <Grid item xs={6}>
              <Box className={classes.canvasContainer} style={isMinimized ? {height: '50vw'} : {}}>
                <OverlayComponentCanvas
                clothingType={clothingType}
                fitType={fitType}
                putAtTop={true}
                scaleRatio={isMinimized ? 0.7 : 0.5}
                incrementTogether={true}
                goalFit={goalFit}/>
              </Box>
            </Grid> : 
            null
          }
        </Grid>
      </OverlayComponentBaseContainerWidth>
    </OverlayComponentBaseContainerHorizontal>
  );
  submitChildren.push(
    <OverlayComponentBaseContainerHorizontal
    alignItems='flex-start' >
      <Box>
        {
          selected !== undefined ? 
          <OverlayComponentButton
            formButtonText="PILIH SIZE"
            isTypeSubmit={false}
            handleClick={() => {
              if (selected !== undefined) {
                handleSubmit(selected);
              }
            }}
            buttonMarginTopBottom="10px"
            buttonMarginLeftRight="0px"/> :
          null
        }
      </Box>
    </OverlayComponentBaseContainerHorizontal>
  )

  return (
    <OverlayComponentBaseTemplate alignItems="flex-start">
      {
        isMinimized ? (
          <div/>
        ) : (
          <OverlayComponentTitle text="Pilih size kamu!" />
        )
      }
      <Box>
        {
          children
        }
      </Box>
      <Box>
        {
          submitChildren
        }
      </Box>
    </OverlayComponentBaseTemplate>
  );
}