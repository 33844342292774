import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ProductOnUser from 'common-ts/dist/models/Product';

export interface MktplcStartFlow {
    status: UIOverlayViewStatus

    popupStage: '1-INTRO' | '2-HOWTO'
    marketplaceLinkDict: {[key: string]: string}
}

// Define the initial state using that type
const initialState: MktplcStartFlow = {
    status: UIOverlayViewStatus.OffAndReady,

    popupStage: '1-INTRO',
    marketplaceLinkDict: {}
}

export const overlayMktplcStartSlice = createSlice({
    name: 'overlayMktplcStart',
    initialState,
    reducers: {
        closeOverlayAndReset: (state) => {
            state.status = UIOverlayViewStatus.OffAndReady;
            state.popupStage = '1-INTRO';
        },

        openOverlay: (state, action) => {
            state.status = UIOverlayViewStatus.On;
            state.marketplaceLinkDict = action.payload;
            state.popupStage = '1-INTRO';
        },

        changePopupStage(state, action) {
            state.popupStage = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { closeOverlayAndReset, openOverlay, changePopupStage } = overlayMktplcStartSlice.actions

export default overlayMktplcStartSlice.reducer